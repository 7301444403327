@import url("https://fonts.googleapis.com/css2?family=Archivo+Narrow:wght@600&display=swap");
@import url("https://p.typekit.net/p.css?s=1&k=eht2nwg&ht=tk&f=47890.47891.47892.47893.47894&a=56266181&app=typekit&e=css");

@font-face {
  font-family: "Switzer Variable";
  font-weight: 100 900;
  font-display: swap;
  font-style: normal;
  src: url("../fonts/Switzer-Variable.ttf") format("truetype");
}
@font-face {
  font-family: "Switzer Variable";
  font-weight: 100 900;
  font-display: swap;
  font-style: italic;
  src: url("../fonts/Switzer-VariableItalic.ttf") format("truetype");
}
