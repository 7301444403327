.main-width {
  width: 100%;
  max-width: 1500px;
  padding-left: 100px;
  padding-right: 100px;
  margin-left: auto;
  margin-right: auto;
}

.block {
  display: block;
}

.w-full {
  width: 100%;
}

.flex {
  display: flex;
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.align-center {
  align-items: center;
}

.mr-25 {
  margin-right: 25px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mt-20 {
  margin-top: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}

@media all and (max-width: 768px) {
  .main-width {
    padding-left: 25px;
    padding-right: 25px;
  }
}
